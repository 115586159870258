type useParamsProps = {
  activeBestPractices: string[];
  activeContentType: string[];
  activeTopics: string[];
  offset: number;
  records: number;
  search: string;
};

export const useParams = ({
  activeBestPractices,
  activeContentType,
  activeTopics,
  offset,
  records,
  search,
}: useParamsProps) => {
  return {
    search: search ? search : null,
    bestPractices: !!activeBestPractices.length ? activeBestPractices.map((activeItem) => activeItem).join(",") : null,
    contentTypes: !!activeContentType.length ? activeContentType.map((activeItem) => activeItem).join(",") : null,
    topics: !!activeTopics.length ? activeTopics.map((activeItem) => activeItem).join(",") : null,
    numberPerPage: records,
    offset: offset,
  };
};
