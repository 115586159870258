import { API, api } from "modules/api";
import { ResourceHubItemType, ResourceHubGetResponse } from "modules/api/endpoints/resource-hub";

export type RecordsProps = {
  bestPractices: string | null;
  contentTypes: string | null;
  numberPerPage: number;
  offset: number;
  records?: number;
  topics: string | null;
};

type useFetchResourceHubDataProps = {
  emptyMessage: string;
  fetchPaginatedData: boolean;
  params?: RecordsProps;
  paingatedParams?: RecordsProps;
  urlParams?: RecordsProps;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  setFeaturedPost: (value: React.SetStateAction<ResourceHubItemType | null>) => void;
  setResourceHubEntries: (value: React.SetStateAction<ResourceHubItemType[] | null>) => void;
  records: number;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useFetchResourceHubData = async ({
  emptyMessage,
  fetchPaginatedData,
  params,
  urlParams,
  setIsLoading,
  setTotalRecords,
  setErrorMessage,
  setOffset,
  setFeaturedPost,
  setResourceHubEntries,
  records,
  setReset,
  setSkeleton,
}: useFetchResourceHubDataProps) => {
  setErrorMessage(emptyMessage);
  !fetchPaginatedData && setReset(false);
  try {
    const resourceHubResponse = await api(API.GET_RESOURCE_HUB(urlParams ? urlParams : params)).then(
      (res: ResourceHubGetResponse) => res.data
    );
    setFeaturedPost(resourceHubResponse.featuredItem);
    if (resourceHubResponse.results.length === 0) {
      // No records
      setIsLoading(false);
      setSkeleton(false);
      setTotalRecords(0);
      return;
    }
    // Successful
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(resourceHubResponse.totalRecords);
    if (fetchPaginatedData) {
      setOffset((currOffset) => currOffset + records);
      setResourceHubEntries((currEntries) => {
        if (currEntries) {
          return [...currEntries, ...resourceHubResponse.results];
        }
        return resourceHubResponse.results;
      });
    } else {
      setOffset(records);
      setResourceHubEntries(resourceHubResponse.results);
    }
  } catch (error) {
    // Error
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(0);
    setFeaturedPost(null);
    setErrorMessage(`${error}.`);
  }
};
