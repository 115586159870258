import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { AutocompleteItemType } from "modules/api/endpoints/autocomplete";
import { useEffect } from "react";
import { useFetchAutocompleteData } from "./fetchAutocompleteData";

export const useMuiAutocomplete = (
  autocompleteEntries: AutocompleteItemType[] | null,
  setAutocompleteEntries: (value: React.SetStateAction<AutocompleteItemType[] | null>) => void
) => {
  /* MUI - Autocomplete Hook */
  const { getClearProps, getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      autoSelect: true,
      id: "resourceHubSearch",
      freeSolo: true,
      options: autocompleteEntries || [],
      getOptionLabel: (option: any) => (option.text ? option.text : typeof value === "string" ? value : ""),
      getOptionSelected: (option: any, value: any) => (option.text ? option.text === value.text : (null as any)),
    });
  /* MUI - Autocomplete Search & Ref */
  var { value, ref }: any = getInputProps();

  /* MUI - Input Filters */
  const search = value;
  const searchRef = ref;

  /* Load Autocomplete Data */
  useEffect(() => {
    useFetchAutocompleteData({
      params: {
        search: search ? search : "?",
      },
      setAutocompleteEntries,
    });
  }, [search]);

  return {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    search,
    searchRef,
  };
};
