import { useDataLayer } from "modules/gtm";

type useGtmTriggerProps = {
  search: any;
  activeBestPractices: string[];
  activeTopics: string[];
  activeContentType: string[];
};

export const useGtmTrigger = ({ search, activeBestPractices, activeTopics, activeContentType }: useGtmTriggerProps) => {
  const cleanName = search ? `${search}` : null;
  const cleanActiveBestPractices =
    activeBestPractices.length > 0 ? `${activeBestPractices.filter((i) => i !== null).join(", ")}` : null;
  const cleanActiveTopics = activeTopics.length > 0 ? `${activeTopics.filter((i) => i !== null).join(", ")}` : null;
  const cleanActiveContentType =
    activeContentType.length > 0 ? `${activeContentType.filter((i) => i !== null).join(", ")}` : null;
  const gtmParams = [cleanName, cleanActiveBestPractices, cleanActiveTopics, cleanActiveContentType].filter(
    (i) => i !== null
  );

  return useDataLayer({
    action: "Search",
    category: "Resource Hub",
    label: gtmParams.length === 1 ? gtmParams.join("") : gtmParams.join(", "),
  });
};
