import { useEffect } from "react";

type useResetFilterProps = {
  setRecords: (value: React.SetStateAction<number>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  searchRef: React.RefObject<HTMLInputElement>;
  searchButtonRef: React.RefObject<HTMLButtonElement>;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useResetFilter = ({
  setRecords,
  setOffset,
  searchRef,
  searchButtonRef,
  setReset,
}: useResetFilterProps) => {
  const resetFilters = () => {
    setRecords(14);
    setOffset(0);
    setReset(true);
  };
  useEffect(() => {
    searchRef &&
      searchRef.current &&
      searchRef.current.addEventListener("keydown", (event) => {
        event.key === "Enter" && resetFilters();
      });
    return () => {
      searchRef &&
        searchRef.current &&
        searchRef.current.removeEventListener("keydown", (event) => {
          event.key === "Enter" && resetFilters();
        });
    };
  }, [searchRef]);

  useEffect(() => {
    searchButtonRef && searchButtonRef.current && searchButtonRef.current.addEventListener("click", resetFilters);
    return () => {
      searchButtonRef && searchButtonRef.current && searchButtonRef.current.removeEventListener("click", resetFilters);
    };
  }, [searchButtonRef]);

  return { resetFilters };
};
