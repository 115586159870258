import { API, api } from "modules/api";
import { AutocompleteGetResponse, AutocompleteItemType } from "modules/api/endpoints/autocomplete";

export type ResultsProps = {
  search: string | null;
};

type useFetchAutocompleteDataProps = {
  params: ResultsProps;
  // setAutocompleteIsLoading: (value: React.SetStateAction<boolean>) => void;
  setAutocompleteEntries: (value: React.SetStateAction<AutocompleteItemType[] | null>) => void;
};

export const useFetchAutocompleteData = async ({
  params,
  // setAutocompleteIsLoading,
  setAutocompleteEntries,
}: useFetchAutocompleteDataProps) => {
  try {
    const autocompleteResponse = await api(API.GET_RESOURCE_HUB_AUTOCOMPLETE(params)).then(
      (res: AutocompleteGetResponse) => res.data
    );
    if (autocompleteResponse.results.length === 0) {
      // No records
      // setAutocompleteIsLoading(false);
      setAutocompleteEntries(null);
      return;
    }
    // Successful
    // setAutocompleteIsLoading(false);
    setAutocompleteEntries(autocompleteResponse.results);
  } catch (error) {
    // Error
    // setAutocompleteIsLoading(false);
    setAutocompleteEntries(null);
  }
};
