import { useEffect, useState } from "react";
import { ResourceHubQueryTypes } from "templates/resource-hub";
import { BestPracticesType, TopicsType } from "templates/resource-hub/types";

type usePopularSearchTagsProps = {
  activeBestPractices: string[];
  activeTopics: string[];
  bestPractices: BestPracticesType[];
  data: ResourceHubQueryTypes;
  handleSearchButtonClick: () => void;
  setActiveBestPractices: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveContentType: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveTopics: React.Dispatch<React.SetStateAction<string[]>>;
  setBestPracticesURL: React.Dispatch<React.SetStateAction<string[]>>;
  setContentTypeURL: React.Dispatch<React.SetStateAction<string[]>>;
  setPopularSearchTags: React.Dispatch<React.SetStateAction<TopicsType[] | BestPracticesType[]>>;
  setTopicsURL: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdateURL: React.Dispatch<React.SetStateAction<boolean>>;
  resetFilters: () => void;
  topics: TopicsType[];
};

export type PopularSearchTagProp = {
  node: TopicsType | BestPracticesType;
};

export const usePopularSearchTags = ({
  activeBestPractices,
  activeTopics,
  bestPractices,
  data,
  handleSearchButtonClick,
  setActiveBestPractices,
  setActiveContentType,
  setActiveTopics,
  setBestPracticesURL,
  setContentTypeURL,
  setPopularSearchTags,
  setTopicsURL,
  setUpdateURL,
  resetFilters,
  topics,
}: usePopularSearchTagsProps) => {
  const [tagClicked, setTagClicked] = useState(false);

  const topicTags = [...data.topicList.edges.filter((topicTag: PopularSearchTagProp) => topicTag.node.popular_search)]
    .map((item) => item.node)
    .sort((a, b) => {
      if (a.popular_search && b.popular_search) return b.popular_search - a.popular_search;
      return 0;
    });

  const bestPracticeTags = [
    ...data.bestPracticeList.edges.filter(
      (bestPracticeTag: PopularSearchTagProp) => bestPracticeTag.node.popular_search
    ),
  ]
    .map((item) => item.node)
    .sort((a, b) => {
      if (a.popular_search && b.popular_search) return b.popular_search - a.popular_search;
      return 0;
    });

  const selectTags = () => {
    return [...topicTags.slice(0, 6), ...bestPracticeTags.slice(0, 3)].sort((a, b) => {
      if (a.popular_search && b.popular_search) return b.popular_search - a.popular_search;
      return 0;
    });
  };

  useEffect(() => {
    setPopularSearchTags(selectTags());
  }, [data]);

  const handlePopularSearchTag = (tagId: string) => {
    resetFilters();
    setTagClicked(true);

    const foundActiveBestPracticesId = activeBestPractices.includes(tagId);
    const foundActiveTopicId = activeTopics.includes(tagId);
    const foundBestPractices = bestPractices.find((bestPractice) => bestPractice.slug === tagId);
    const foundTopic = topics.find((topic) => topic.slug === tagId);

    if (foundActiveBestPracticesId || foundActiveTopicId) {
      setActiveBestPractices([]);
      setBestPracticesURL([]);
      setActiveTopics([]);
      setTopicsURL([]);
      setActiveContentType([]);
      setContentTypeURL([]);
      setUpdateURL(true);
      return;
    }

    if (foundBestPractices && !foundActiveBestPracticesId) {
      const { slug } = foundBestPractices;
      setActiveBestPractices([slug]);
      setBestPracticesURL([slug]);
      setActiveTopics([]);
      setTopicsURL([]);
    }
    if (foundTopic && !foundActiveTopicId) {
      const { slug } = foundTopic;
      setActiveTopics([slug]);
      setTopicsURL([slug]);
      setActiveBestPractices([]);
      setBestPracticesURL([]);
    }

    if ((foundTopic && !foundActiveTopicId) || (foundBestPractices && !foundActiveBestPracticesId)) {
      setUpdateURL(true);
      setActiveContentType([]);
      setContentTypeURL([]);
    }
  };

  useEffect(() => {
    if (!tagClicked) return;
    handleSearchButtonClick();
    setTagClicked(false);
  }, [tagClicked]);

  return { handlePopularSearchTag };
};
