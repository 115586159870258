import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { usePopularSearchTags } from "components/ResourceHub/hooks/popularSearchTags";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { useFetchResourceHubData } from "components/ResourceHub/hooks/fetchResourceHubData";
import { useParams } from "components/ResourceHub/hooks/params";
import { useDropdown } from "components/ResourceHub/hooks/dropdown";
import { useResetFilter } from "components/ResourceHub/hooks/resetFilters";
import { getURLParams, useUpdateUrl } from "components/ResourceHub/hooks/updateUrl";
import { AutocompleteItemType } from "modules/api/endpoints/autocomplete";
import { ResourceHubItemType } from "modules/api/endpoints/resource-hub";
import { ResourceHubFilterPreviewProps } from "components/ResourceHub/Filters/types";
import { BestPracticesType, TopicsType, ContentType, MuiAutocompleteProps } from "./types";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import * as ResourceHub from "components/ResourceHub";
import { useMuiAutocomplete } from "components/ResourceHub/hooks/autocomplete";
import { useGtmTrigger } from "components/ResourceHub/hooks/gtmTrigger";
import { NewsletterPopupType } from "modules/newsletter/types";

export type ResourceHubQueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  resourceHubList: {
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    filterConfiguration: {
      noResultsMessage: string;
    };
  };
  bestPracticeList: {
    edges: {
      node: BestPracticesType;
    }[];
  };
  contentTypeList: {
    edges: {
      node: ContentType;
    }[];
  };
  topicList: {
    edges: {
      node: TopicsType;
    }[];
  };
};

type ResourceHubPageProps = { data: ResourceHubQueryTypes; location: any };

const ResourceHubPage: React.FC<ResourceHubPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.resourceHubList;
  /* Check If Url Is Root */
  const isRootUrl = location.pathname === "/resource-hub/" || location.pathname === "/resource-hub";
  /* Check If Url Contains Dropdown Values */
  const ifBestPractices = () => {
    const filteredItems = data.bestPracticeList.edges.filter((ct) => getURLParams().includes(ct.node.slug));
    return filteredItems.map((i) => i.node.slug);
  };

  const ifContentType = () => {
    const filteredItems = data.contentTypeList.edges.filter((ct) => getURLParams().includes(ct.node.slug));
    return filteredItems.map((i) => i.node.slug);
  };
  const ifTopics = () => {
    const filteredItems = data.topicList.edges.filter((ct) => getURLParams().includes(ct.node.slug));
    return filteredItems.map((i) => i.node.slug);
  };
  /* Ajax Data */
  const [resourceHubEntries, setResourceHubEntries] = useState<ResourceHubItemType[] | null>([]);
  const [autocompleteEntries, setAutocompleteEntries] = useState<AutocompleteItemType[] | null>([]);

  /* Dropdown Filter */
  const [bestPractices, setBestPractices] = useState<BestPracticesType[]>([]);
  const [bestPracticesDropdownOpen, setBestPracticesDropdownOpen] = useState(false);
  const [activeBestPractices, setActiveBestPractices] = useState<string[]>([]);
  const bestPracticesButtonRef = useRef<HTMLButtonElement>(null);
  const bestPracticesDropdownRef = useRef<HTMLDivElement>(null);
  const [contentType, setContentType] = useState<ContentType[]>([]);
  const [contentTypeDropdownOpen, setContentTypeDropdownOpen] = useState(false);
  const [activeContentType, setActiveContentType] = useState<string[]>([]);
  const contentTypeButtonRef = useRef<HTMLButtonElement>(null);
  const contentTypeDropdownRef = useRef<HTMLDivElement>(null);
  const [topics, setTopics] = useState<TopicsType[]>([]);
  const [topicsDropdownOpen, setTopicsDropdownOpen] = useState(false);
  const [activeTopics, setActiveTopics] = useState<string[]>([]);
  const topicsButtonRef = useRef<HTMLButtonElement>(null);
  const topicsDropdownRef = useRef<HTMLDivElement>(null);
  /* Pagination Control */
  const [counter, updateCounter] = useState(0);
  const [records, setRecords] = useState(14);
  const [offset, setOffset] = useState(0);
  const [reset, setReset] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);
  /* Search Button */
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  /* Ajax States */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  /* Validation Messages */
  const emptyMessage = page?.filterConfiguration?.noResultsMessage;
  /* Results Ref */
  const resultsRef = useRef<HTMLDivElement>(null);
  /* URLs */
  const [updateURL, setUpdateURL] = useState(false);
  const [contentTypeURL, setContentTypeURL] = useState<string[]>([]);
  const [bestPracticesURL, setBestPracticesURL] = useState<string[]>([]);
  const [topicsURL, setTopicsURL] = useState<string[]>([]);
  /* Popular Search Tags */
  const [popularSearchTags, setPopularSearchTags] = useState<TopicsType[] | BestPracticesType[]>([]);
  /* Featured Post */
  const [featuredPost, setFeaturedPost] = useState<ResourceHubItemType | null>(null);
  /* Page Load */
  const [pageLoad, setPageLoad] = useState(false);

  /* MUI Autocomplete */
  const {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    search,
    searchRef,
  } = useMuiAutocomplete(autocompleteEntries, setAutocompleteEntries);

  /* Set URL */
  useUpdateUrl({
    bestPractices,
    bestPracticesURL,
    contentType,
    contentTypeURL,
    setActiveBestPractices,
    setActiveContentType,
    setActiveTopics,
    setBestPracticesURL,
    setContentTypeURL,
    setTopicsURL,
    topics,
    topicsURL,
    updateURL,
  });

  /* API Parameters */
  const params = useParams({
    activeBestPractices,
    activeContentType,
    activeTopics,
    offset,
    records,
    search,
  });

  /* URL API Parameters */
  const urlParams = useParams({
    activeBestPractices: ifBestPractices(),
    activeContentType: ifContentType(),
    activeTopics: ifTopics(),
    offset,
    records,
    search,
  });

  /* Load API Data */
  useEffect(() => {
    if (isRootUrl) {
      setIsLoading(true);
      setSkeleton(true);
      setSearchClicked(true);
      const fetchPaginatedData = false;
      useGtmTrigger({ search, activeBestPractices, activeTopics, activeContentType });
      useFetchResourceHubData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setFeaturedPost,
        setResourceHubEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, []);

  /* Load API Data Via URL Pathanme */
  useEffect(() => {
    if (pageLoad) return;
    if (!isRootUrl && (activeTopics.length > 0 || activeContentType.length > 0 || activeBestPractices.length > 0)) {
      setPageLoad(true);
      setIsLoading(true);
      setSkeleton(true);
      setSearchClicked(true);
      const fetchPaginatedData = false;
      useGtmTrigger({ search, activeBestPractices, activeTopics, activeContentType });
      useFetchResourceHubData({
        urlParams,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setFeaturedPost,
        setResourceHubEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [activeTopics, activeBestPractices, activeContentType]);

  /* Dropdown Handlers */
  const { handleActiveFilteredItems } =
    useDropdown({
      data,
      bestPractices,
      contentType,
      setActiveBestPractices,
      setActiveContentType,
      setActiveTopics,
      setBestPractices,
      setBestPracticesURL,
      setContentType,
      setContentTypeURL,
      setTopics,
      setTopicsURL,
      setUpdateURL,
      topics,
    });

  /* Search Button Handler */
  const handleSearchButtonClick = () => {
    // Allows MUI Autocomplete autoSelect to work when pressing Search Button
    if ("activeElement" in document) (document?.activeElement as any)?.blur();
    setSearchClicked(true);
    setSkeleton(true);
  };

  useEffect(() => {
    if (!searchClicked) return;
    if (reset) {
      useGtmTrigger({ search, activeBestPractices, activeTopics, activeContentType });
      setIsLoading(true);
      const fetchPaginatedData = false;
      useFetchResourceHubData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setFeaturedPost,
        setResourceHubEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [searchClicked, reset]);

  /* Load More Button Handler */
  const handleLoadMoreClick = () => {
    setSearchClicked(false);
    updateCounter((currCounter) => currCounter + 1);
  };

  /* Load Paginated Results */
  useEffect(() => {
    if (counter === 0) return;
    setIsLoading(true);
    const fetchPaginatedData = true;
    useFetchResourceHubData({
      params,
      fetchPaginatedData,
      setIsLoading,
      setTotalRecords,
      setErrorMessage,
      setOffset,
      setFeaturedPost,
      setResourceHubEntries,
      emptyMessage,
      records,
      setReset,
      setSkeleton,
    });
  }, [counter]);

  /* Reset Filter On Enter/Click With Filter Controls */
  const { resetFilters } = useResetFilter({
    setRecords,
    setOffset,
    searchRef,
    searchButtonRef,
    setReset,
  });

  /* Popular Search Tags */
  const { handlePopularSearchTag } = usePopularSearchTags({
    activeBestPractices,
    activeTopics,
    bestPractices,
    data,
    handleSearchButtonClick,
    resetFilters,
    setActiveBestPractices,
    setActiveContentType,
    setActiveTopics,
    setBestPracticesURL,
    setContentTypeURL,
    setPopularSearchTags,
    setTopicsURL,
    setUpdateURL,
    topics,
  });

  /* Newsletter */
  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  /* MUI Autocomplete Props */
  const muiAutocompleteProps: MuiAutocompleteProps = {
    getClearProps: getClearProps,
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    getListboxProps: getListboxProps,
    getOptionProps: getOptionProps,
    groupedOptions: groupedOptions,
  };

  /* Filter Props */
  const filterProps: ResourceHubFilterPreviewProps = {
    muiAutocompleteProps: muiAutocompleteProps,
    bestPractices: bestPractices,
    handleActiveFilteredItems: handleActiveFilteredItems,
    handleSearchButtonClick: handleSearchButtonClick,
    contentType: contentType,
    search: search,
    searchButtonRef: searchButtonRef,
    topics: topics,
  };

  return (
    <ResourceHub.Template
      activeTopics={activeTopics}
      activeBestPractices={activeBestPractices}
      activeContentType={activeContentType}
      defaultMeta={data.contentstackGlobalMeta}
      errorMessage={errorMessage}
      featuredPost={featuredPost}
      filterProps={filterProps}
      handleLoadMoreClick={handleLoadMoreClick}
      handlePopularSearchTag={handlePopularSearchTag}
      isLoading={isLoading}
      location={location}
      meta={page?.meta}
      popularSearchTags={popularSearchTags}
      resourceHubEntries={resourceHubEntries}
      resultsRef={resultsRef}
      skeleton={skeleton}
      totalRecords={totalRecords}
    />
  );
};

export const query = graphql`
  query {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    resourceHubList: contentstackResourceHubListPage {
      ...metaResourceHubList
      newsletter {
        show_popup_page
      }
      filterConfiguration: filter_configuration {
        noResultsMessage: no_results_message
      }
    }
    bestPracticeList: allContentstackResourceHubBestPractices {
      edges {
        node {
          title
          slug
          id
          colour
          popular_search
        }
      }
    }
    contentTypeList: allContentstackResourceHubContentTypes {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    topicList: allContentstackResourceHubTopics {
      edges {
        node {
          id
          slug
          title
          popular_search
        }
      }
    }
  }
`;

export default ResourceHubPage;
