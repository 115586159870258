import { useWindow } from "modules/utils";
import { useEffect } from "react";
import { BestPracticesType, ContentType, TopicsType } from "templates/resource-hub/types";

type UpdateUrlProps = {
  bestPractices: BestPracticesType[];
  bestPracticesURL: string[];
  contentType: ContentType[];
  contentTypeURL: string[];
  setActiveBestPractices: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveContentType: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveTopics: React.Dispatch<React.SetStateAction<string[]>>;
  setBestPracticesURL: React.Dispatch<React.SetStateAction<string[]>>;
  setContentTypeURL: React.Dispatch<React.SetStateAction<string[]>>;
  setTopicsURL: React.Dispatch<React.SetStateAction<string[]>>;
  topics: TopicsType[];
  topicsURL: string[];
  updateURL: boolean;
};

export const useUpdateUrl = ({
  bestPractices,
  bestPracticesURL,
  contentType,
  contentTypeURL,
  setActiveBestPractices,
  setActiveContentType,
  setActiveTopics,
  setBestPracticesURL,
  setContentTypeURL,
  setTopicsURL,
  topics,
  topicsURL,
  updateURL,
}: UpdateUrlProps) => {
  useEffect(() => {
    if (!bestPractices) return;
    const filterValues = getURLParams();
    if (!filterValues.length) return;
    const filteredItems = bestPractices.filter((ct) => filterValues.includes(ct.slug));
    setActiveBestPractices(() => filteredItems.map((i) => i.slug));
    setBestPracticesURL(() => filteredItems.map((i) => i.slug));
  }, [bestPractices]);

  useEffect(() => {
    if (!contentType) return;
    const filterValues = getURLParams();
    if (!filterValues.length) return;
    const filteredItems = contentType.filter((ct) => filterValues.includes(ct.slug));
    setActiveContentType(() => filteredItems.map((i) => i.slug));
    setContentTypeURL(() => filteredItems.map((i) => i.slug));
  }, [contentType]);

  useEffect(() => {
    if (!topics) return;
    const filterValues = getURLParams();
    if (!filterValues.length) return;
    const filteredItems = topics.filter((ct) => filterValues.includes(ct.slug));
    setActiveTopics(() => filteredItems.map((i) => i.slug));
    setTopicsURL(() => filteredItems.map((i) => i.slug));
  }, [topics]);

  useEffect(() => {
    if (!updateURL) return;
    const { origin } = window.location;
    history.replaceState({}, "", `${origin}/resource-hub/${getUpdatedURL()}`);
  }, [contentTypeURL, bestPracticesURL, topicsURL, updateURL]);

  const getUpdatedURL = () => {
    const allUrls = [[...contentTypeURL], [...bestPracticesURL], [...topicsURL]];

    return allUrls
      .map((url) => {
        if (url.length > 0) {
          return `${url}/`;
        }
      })
      .filter((i) => i)
      .join("");
  };
};

export const getURLParams = () => {
  const baseURL = "/resource-hub/";
  const params = useWindow()
    ? window.location.pathname.substring(baseURL.length, window.location.pathname.length).replace(/\//g, ",").split(",")
    : "";

  return useWindow() && params[0] === "" ? [] : params;
};
