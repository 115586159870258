import { useEffect } from "react";
import { DropdownType } from "components/DropdownSelect/constants";
import { ResourceHubQueryTypes } from "templates/resource-hub";
import { BestPracticesType, ContentType, TopicsType } from "templates/resource-hub/types";

type useDropdownProps = {
  data: ResourceHubQueryTypes;
  bestPractices: BestPracticesType[];
  contentType: ContentType[];
  setActiveBestPractices: (value: React.SetStateAction<string[]>) => void;
  setActiveContentType: (value: React.SetStateAction<string[]>) => void;
  setActiveTopics: (value: React.SetStateAction<string[]>) => void;
  setBestPractices: (value: React.SetStateAction<BestPracticesType[]>) => void;
  setBestPracticesURL: React.Dispatch<React.SetStateAction<string[]>>;
  setContentType: (value: React.SetStateAction<ContentType[]>) => void;
  setContentTypeURL: React.Dispatch<React.SetStateAction<string[]>>;
  setTopics: (value: React.SetStateAction<TopicsType[]>) => void;
  setTopicsURL: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdateURL: React.Dispatch<React.SetStateAction<boolean>>;
  topics: TopicsType[];
};

export const useDropdown = ({
  data,
  bestPractices,
  contentType,
  setActiveContentType,
  setActiveBestPractices,
  setActiveTopics,
  setBestPractices,
  setBestPracticesURL,
  setContentType,
  setContentTypeURL,
  setTopics,
  setTopicsURL,
  setUpdateURL,
  topics,
}: useDropdownProps) => {
  useEffect(() => {
    if (!data) return;
    setBestPractices(() => {
      return data.bestPracticeList.edges
        .map((item: any) => item.node)
        .sort((a: any, b: any) => a.slug.localeCompare(b.slug));
    });
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setContentType(() => {
      return data.contentTypeList.edges
        .map((item: any) => item.node)
        .sort((a: any, b: any) => a.slug.localeCompare(b.slug));
    });
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setTopics(() => {
      return data.topicList.edges.map((item: any) => item.node).sort((a: any, b: any) => a.slug.localeCompare(b.slug));
    });
  }, [data]);

  const getActiveIds = (arr: string[], id: string) => {
    if (!id) return [];
    if (arr.includes(id)) {
      return arr.filter((activeId) => activeId !== id);
    }
    return [...arr, id];
  };

  const handleActiveFilteredItems = async (slug: string, type: DropdownType) => {
    setUpdateURL(false);
    if (type === DropdownType.BEST_PRACTICES) {
      setActiveBestPractices((activeIds) => {
        return getActiveIds(activeIds, slug);
      });
      setBestPracticesURL((activeURLs) => {
        const activeURL = bestPractices.filter((item) => item.slug === slug)[0];
        return getActiveIds(activeURLs, activeURL?.slug);
      });
    }
    if (type === DropdownType.CONTENT_TYPE) {
      setActiveContentType((activeIds) => {
        return getActiveIds(activeIds, slug);
      });
      setContentTypeURL((activeURLs) => {
        const activeURL = contentType.filter((item) => item.slug === slug)[0];
        return getActiveIds(activeURLs, activeURL?.slug);
      });
    }
    if (type === DropdownType.TOPICS) {
      setActiveTopics((activeIds) => {
        return getActiveIds(activeIds, slug);
      });
      setTopicsURL((activeURLs) => {
        const activeURL = topics.filter((item) => item.slug === slug)[0];
        return getActiveIds(activeURLs, activeURL?.slug);
      });
    }
    setUpdateURL(true);
  };

  return { handleActiveFilteredItems };
};
